<template>
<div class="alarm-setting-container">
  <div class="setting-header">
    <div class="setting-header-title">{{showLang('total.types',types.length)}}</div>
    <Button v-if="funCodes(1603)" type="primary" @click="saveSetting" class="mr10">{{showLang('com.op.save')}}</Button>
    <Button v-if="funCodes(1602)" type="primary" @click="getList">{{showLang('com.op.refresh')}}</Button>
  </div>
  <div class="setting-content">
    <div class="alarm-type-group" v-for="(group, gidx) in groups" :key="gidx">
      <div class="group-header">{{gidx+1}}、{{group}}【{{types.filter(p => p.group == group).length}}】</div>
      <div class="alarm-type-item" v-for="(item, idx) in types.filter(p => p.group == group)" :key="idx">
        <div class="item-name">{{idx+1}}、{{item.name}}</div>
        <div class="item-remark">{{item.remark}}</div>
        <div class="item-op">
          <Checkbox v-model="item.enable" class="chk-item">{{showLang('com.is.enable')}}</Checkbox>
        </div>
        <div class="item-op">
          <Select :placeholder="showLang('save.select')" v-model="item.level">
            <Option v-for="(level, lidx) in levels" :key="lidx" :value="level.code">{{level.name}}</Option>
          </Select>
        </div>
        <div class="item-op">
          <Checkbox v-model="item.notify" class="chk-item">{{showLang('alarm.type.notify.enable')}}</Checkbox>
        </div>
        <div class="item-op">
          <Checkbox v-model="item.done" class="chk-item">{{showLang('alarm.type.done.enable')}}</Checkbox>
        </div>
        <!-- <div class="item-op"></div> -->
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { mapState,mapGetters } from 'vuex'
export default {
  name: 'LightAlarmTypes',
  components:{
  },
  props: {
  },
  data () {
    return {
      tabHeight: 600,
      loading: false,
      groups: [],
      types: [],
      levels: [],
    }
  },
  computed: {
    ...mapGetters('auth', ['showLang', 'funCodes']),
    ...mapState('cmd', ['windowOnResize', 'cmds']),
    ...mapState('common', ['alarmLevels', 'productCodes', 'alarmTypes', 'alarmGroupList']),
  },
  watch: {
    windowOnResize(){
    },
  },
  mounted: function(){
    this.getLevels();
    this.getList();
  },
  destroyed: function(){
  },
  methods: {
    getLevels: function(){
      this.$axios.post(`station/alarm/QueryLevels`, {}).then(res => {
        if(res.code == 0){
          this.$set(this, 'levels', res.data);
        }
      });
    },
    getList: function(){
      this.$axios.post(`station/alarm/QueryLightTypeSetting`, {}).then(res => {
        if(res.code == 0){
          this.$set(this, 'groups', res.data.catalogs);
          this.$set(this, 'types', res.data.types);
        }
      });
    },
    saveSetting: function(){
      // console.log('save types', this.types);
      this.$axios.post(`station/alarm/SaveLightTypeSetting`, {list: this.types}).then(res => {
        if(res.code == 0){
          this.$Message.info(this.showLang('com.tips.save'))
        }
      });
    },
  }
}
</script>
<style scoped>
.alarm-setting-container{
  /* border: solid 1px red; */
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.setting-header{
  border: solid 1px rgba(0, 37, 158, 0.432);
  height: 50px;
  flex: none;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
}
.setting-header-title{
  font-size: 25px;
  font-weight: bolder;
  width: 100px;
  flex: auto;
}
.setting-content{
  border: solid 1px rgba(0, 37, 158, 0.432);
  height: 500px;
  flex: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 10px;
}
.alarm-type-group{
  border: solid 1px rgba(0, 37, 158, 0.432);
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 5px; */
}
.group-header{
  border-bottom: solid 1px rgba(0, 37, 158, 0.432);
  font-weight: bolder;
  font-size: 25px;
  padding: 0 10px;
  display: flex;
}
.alarm-type-item{
  border-bottom: solid 1px rgba(0, 37, 158, 0.432);
  display: flex;
}
.item-name{
  border-right: solid 1px rgba(0, 37, 158, 0.432);
  width: 220px;
  flex: none;
  font-size: 18px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.item-remark{
  border-right: solid 1px rgba(0, 37, 158, 0.432);
  width: 100px;
  padding: 0 10px;
  flex: auto;
  display: flex;
  align-items: center;
}
.item-op{
  border-right: solid 1px rgba(0, 37, 158, 0.432);
  width: 150px;
  flex: none;
  padding: 3px 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>